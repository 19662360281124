import Toastify from "toastify-js"
import "./style.pcss"
import en from "/@/assets/locales/en"

let toastIsShowing = false

const messageHtml = `
  <div
    class="
      flex
      flex-col
      border
      border-solid
      border-alert-error
      rounded-lg
      bg-alert-error
      px-4
      py-3"
    data-testid="toast-error-message"
  >
    <div class="flex items-center justify-between w-full">
      <h3 class="font-xxx-small-700-caps text-alerts-error">
        ${en.errors.errorToast.title}
      </h3>
      <div>
        <span class="tw-icon-Exit cursor-pointer text-general-secondary-text" />
      </div>
    </div>
    <div class="flex gap-x-medium max-w-sm mt-small">
      <p class="flex-1 items-center flex font-small-400">
        ${en.errors.errorToast.message}
      </p>
      <div class="mt-2">
        <a
          class="
            hover:no-underline
            cursor-pointer
            flex-1
            text-center
            px-2.5
            py-2
            rounded
            bg-button-login
            font-x-small-500
          "
          href="${en.errors.errorToast.buttonLink}"
        >
          ${en.errors.errorToast.buttonText}
        </a>
      </div>
    </div>
  </div>
`

const createNode = () => {
  const div = document.createElement("div")
  div.innerHTML = messageHtml.trim()
  return div.firstChild
}

const toast = Toastify({
  callback: () => (toastIsShowing = false),
  close: false,
  duration: -1,
  node: createNode(),
  onClick: () => {
    toastIsShowing = false
    toast.hideToast()
  },
  position: "center",
})

export const showToast = (event) => {
  console.error(event)
  if (!toastIsShowing) {
    toast.showToast()
    toastIsShowing = true
  }
}

window.addEventListener("error", showToast)
window.addEventListener("unhandledrejection", showToast)
